import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import get from "lodash/get";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { renderFecha } from "js/utils/Helper";
import { numberWithCommas } from "js/utils";
import { Checkbox } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  }
});

function TablaSegmentos(props) {
  const { classes, data, handleClick } = props;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="right">ID</TableCell>
            <TableCell align="right">Fecha de creación</TableCell>
            <TableCell>Nombre</TableCell>
            <TableCell align="right">Descripción</TableCell>
            <TableCell align="right">Idioma default</TableCell>
            <TableCell align="right">Acciones</TableCell>
            <TableCell align="right">
              ¿Predeterminado?
              <Tooltip title="El segmento predeterminado es el que se utiliza por defecto en el programa para la creación de usuarios">
                <InfoIcon style={{ marginLeft: 4, fontSize: 14 }} />
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((segmento, i) => {
            return (
              <TableRow key={i}>
                <TableCell component="th" scope="row">
                  {numberWithCommas(segmento.idSegmento) || "--"}
                </TableCell>
                <TableCell component="th" scope="row">
                  {renderFecha(segmento.fechaCreacion)}
                </TableCell>
                <TableCell component="th" scope="row">
                  {segmento.nombre || "--"}
                </TableCell>
                <TableCell align="right">
                  {segmento.descripcion || "--"}
                </TableCell>
                <TableCell align="right">
                  {get(segmento, "idiomaDefault.nombre") || "--"}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="Editar"
                    onClick={() => handleClick(segmento)}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
                <TableCell align="right">
                  <Checkbox checked={segmento.predeterminado} disabled={true} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
}

TablaSegmentos.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TablaSegmentos);
