import gql from "graphql-tag";

export const getSegmentoById = gql`
  query getSegmentos($idPrograma: Int) {
    getSegmentos(
      segmentoLike: { idPrograma: $idPrograma }
      segmentoOrder: { column: fechaCreacion, order: desc }
    ) {
      idSegmento
      nombre
      descripcion
      fechaCreacion
      idiomaDefault {
        nombre
        _id
      }
      predeterminado
    }
  }
`;

export const getSegmentosInput = gql`
  query getSegmentos($idPrograma: Int) {
    getSegmentos(segmentoLike: { idPrograma: $idPrograma }) {
      idSegmento
      label: descripcion
      value: nombre
      estado
    }
  }
`;

export const getSegmentos = gql`
  query getSegmentos($segmentoLike: SegmentoSearch) {
    getSegmentos(segmentoLike: $segmentoLike) {
      idSegmento
      nombre
      descripcion
    }
  }
`;

export const createSegmento = gql`
  mutation createSegmento($segmento: SegmentoCreate!) {
    createSegmento(segmento: $segmento) {
      idSegmento
    }
  }
`;

export const updateSegmento = gql`
  mutation updateSegmento($segmento: SegmentoUpdate!) {
    updateSegmento(segmento: $segmento) {
      idSegmento
    }
  }
`;

export default {
  queries: {
    getSegmentos,
    getSegmentoById,
    getSegmentosInput,
    createSegmento,
    updateSegmento
  }
};
